import React from "react";
import "./index.scss";
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Link } from "gatsby";
import LoginForm from "../components/LoginForm";
import NewsletterForm from "../components/NewsletterForm";
import Footer from "../components/Footer";


export default function NotFoundPage(){
    
    return (
        <main className="container">
            <title>Not found</title>
            <div className="top-container not-found-page">
            <header>
                    <nav>
                        <div className="logo-container">
                            <Link to="/">
                                <image className="logo"/>
                            </Link>    
                        </div>
                        <div className="menuItems">
                        <a href="#inquiryForm" className="navButton menuItem-1">Contact</a>
                        <Link to="/login" className="navButton menuItem-2">Partner Login</Link>
                    </div>
                    </nav>
                </header>

                <section className="not-found">
                    <div className="not-found-container">
                        <h2 className="not-found-header">Page not found</h2>
                        <p>
                            Sorry{" "}
                            <span role="img" aria-label="Pensive emoji">
                                😔
                            </span>{" "}
                            we couldn't find what you were looking for.
                        </p>
                        <Link to="/" className="not-found-button-container">
                                <button className="not-found-button">Go home</button>
                        </Link>
                    </div>
                </section>

                <Footer />
            </div>
        </main>
    )
}

